<template>
  <div>
    <div class="intro">
      <h1>{{ $t('auth.login') }}</h1>
      <p>
        {{ $t('auth.sign_in.desc') }}
      </p>
    </div>
    <v-form
      @submit.prevent="submit"
      v-model="isFormValid">
      <!-- EMAIL -->
      <v-text-field
        id="email"
        type="text"
        autocapitalize="none"
        :rules="rules.email"
        inputmode="email"
        v-model="auth.email"
        autocomplete="email"
        required
        :label="`${$t('auth.email')} *`"
        outlined
        :hint="auth.email === '' ? $t('auth.sign_in.email_hint') : ''"
        validate-on-blur
        ref="email"
        @input="checkValidation('email')"
      ></v-text-field>

      <!-- PASSWORD -->
      <password
        id="login-password"
        v-model="auth.password"
        :rules="rules.password"
        validate-on-blur
        ref="login-password"
        @input="checkValidation('login-password', true)"
      ></password>

      <!-- Submission Area -->
      <v-btn
        id="login-submit"
        type="submit"
        color="primary"
        class="black--text"
        block
        x-large
        :loading="submitting"
        :disabled="!isFormValid"
      >
        {{ $t('auth.login') }}
      </v-btn>
    </v-form>

    <br>
    <!-- Forgot Password -->
    <div class="text-uppercase text-center">
      <router-link to="/forgot-password">
        {{ $t('auth.forgot_password') }}
      </router-link>
    </div>
    <br>
    <v-btn
      color="secondary"
      block
      x-large
      to="/signup"
    >
      {{ $t('auth.register') }}
    </v-btn>
  </div>
</template>

<script>
import { email, password } from '@/utils/validators';
import Password from '@/components/Shared/InputFields/Password.vue';

export default {
  name: 'SignIn',
  components: {
    Password,
  },
  data() {
    return {
      auth: {
        email: this.$store.state.registration.inception.email || '',
        password: '',
      },
      isFormValid: true,
      submitting: false,
      rules: {
        email,
        password,
      },
    };
  },
  methods: {
    async submit() {
      this.submitting = true;
      try {
        await this.$store.dispatch('auth/signin', this.auth);
        await this.$store.dispatch('application/loading', true);
      } catch (error) {
        this.$notify.error(error.message);
      } finally {
        this.submitting = false;
      }
    },
    checkValidation(refName, children = false) {
      if (!this.isFormValid) {
        if (children) {
          this.$refs[refName].$children[0].validate();
        } else {
          this.$refs[refName].validate();
        }
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
